<template>
  <section>
    <!-- <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/service" class="is-active"><a>Zlecenia serwisowe</a></router-link>
        </ul>   
      </nav>
    </portal-target> -->
    <div class="ym-toptitle-box">
      <fixed-header :threshold="0" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle">
          <h2 class="title level-left">Zlecenia serwisowe</h2>
          <div v-if="isOnline" class="buttons level-right">
            <a @click.prevent="addNewOrder" class="button is-medium is-dark"
              ><icon name="plus"></icon><span>Dodaj nowe zlecenie</span></a
            >
          </div>
        </div>
      </fixed-header>
    </div>
    <div v-if="unfinishedOrders && unfinishedOrders > 0" class="alert">
      <b
        >Masz {{ unfinishedOrders }}
        <span v-if="unfinishedOrders === 1">niezakończone zlecenie!</span
        ><span v-else-if="unfinishedOrders > 1 && unfinishedOrders < 5"
          >niezakończone zlecenia!</span
        ><span v-else>niezakończonych zleceń!</span></b
      >
      <p>Wypełnij protokół serwisu i zakończ zlecenie.</p>
      <div class="button is-medium is-dark" @click="unfinishedOrdersCall">
        Przefiltruj listę zleceń o niezakończone
      </div>
    </div>
    <div class="ym-columns is-padding-mobile">
      <div class="column is-6">
        <div class="b-field-flex" style="gap: 8px;align-items: center;">
          <div class="ym-searchbox" style="margin-bottom: 0;">
            <b-input
              ref="searchInput"
              v-model="searchQuery"
              size="is-medium"
              placeholder="SZUKAJ..."
            ></b-input>
            <div
              class="field level level-item level-right ym-searchbox-right"
              v-if="this.searchQuery"
            >
              również nieaktywni
              <b-checkbox
                v-model="searchDeleted"
                size="is-medium"
                true-value="1"
                false-value="0"
                @change.native="toggleSearchDeleted"
              >
              </b-checkbox>
            </div>
          </div>
          <TriStateCheckbox
            v-model="filters.unfinished"
            inputId="isUnfinished"
          /><label for="isUnfinished" style="cursor: pointer"
            >Niezakończone</label
          >
        </div>
      </div>
      <div class="column is-3">
        <div class="b-field b-field-flex">
          <b-datepicker
            :mobile-native="false"
            :first-day-of-week="1"
            @input="searchOrdersFromRange"
            placeholder="Przedział dat naprawy"
            v-model="dates"
            icon="calendar-today"
            position="is-bottom-left"
            range
          >
          </b-datepicker>
          <div
            v-if="dates.length > 0"
            @click="allOrders"
            class="button is-primary is-medium has-icon"
          >
            <icon name="x"></icon>
          </div>
        </div>
      </div>
      <div class="column is-3">
        <b-field>
          <b-select
            @input="searchOrdersFromStatus"
            v-model="status_id"
            placeholder="Status zlecenia"
            expanded
          >
            <option :value="null">wszystkie</option>
            <option
              v-for="status in this.$store.state.auth.status"
              :key="status.id"
              :value="status.id"
              :style="`color: ${status.colour}`"
              >{{ status.name }}</option
            >
          </b-select>
        </b-field>
      </div>
      <div class="column is-12 is-gapless">
        <b-table
          :data="orders"
          :row-class="(row, index) => isOldOrder(row)"
          :mobile-cards="false"
          paginated
          backend-pagination
          :total="total"
          :per-page="perPage"
          @page-change="onPageChange"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
        >
          <b-table-column
            field="number"
            label="Zlecenie"
            sortable
            v-slot="props"
          >
            <router-link
              tag="a"
              :to="{ name: 'ServiceDetails', params: { id: props.row.id } }"
              >{{ props.row.number }}</router-link
            >
          </b-table-column>
          <!-- <b-table-column field="device_serial" label="Nr seryjny"  v-slot="props">
            <router-link tag="a" :to="{ name: 'ServiceDetails', params: { id: props.row.id }}">{{ props.row.device_serial }}</router-link>
          </b-table-column> -->
          <b-table-column
            field="device_type"
            label="Data naprawy"
            v-slot="props"
          >
            <router-link
              tag="a"
              class="text-ellipsis"
              :to="{ name: 'ServiceDetails', params: { id: props.row.id } }"
              >{{ props.row.repared_at }}</router-link
            >
          </b-table-column>
          <b-table-column field="repared_at" label="Model" v-slot="props">
            <router-link
              tag="a"
              class="text-ellipsis"
              :to="{ name: 'ServiceDetails', params: { id: props.row.id } }"
              >{{ props.row.device_type }}</router-link
            >
          </b-table-column>
          <b-table-column field="customer_name" label="Klient" v-slot="props">
            <router-link
              class="text-ellipsis"
              tag="a"
              :to="{ name: 'ServiceDetails', params: { id: props.row.id } }"
              >{{ props.row.customer_name }}</router-link
            >
          </b-table-column>
          <b-table-column label="NP" field="is_np" sortable v-slot="props">
            <router-link
              tag="a"
              :to="{ name: 'ServiceDetails', params: { id: props.row.id } }"
              ><span :style="props.row.is_np ? 'color: green' : ''">{{
                props.row.is_np ? "TAK" : "NIE"
              }}</span></router-link
            >
          </b-table-column>
          <b-table-column
            field="status"
            label="Status"
            width="200"
            v-slot="props"
          >
            <router-link
              tag="a"
              :to="{ name: 'ServiceDetails', params: { id: props.row.id } }"
              ><small :style="statusColor(props.row.status_id)">{{
                statusName(props.row.status_id)
              }}</small></router-link
            >
          </b-table-column>
          <template slot="empty" v-if="!loading">
            <section class="section">
              <div
                class="content"
                style="justify-content: center;font-weight: 600;font-size: 16px; color: #000;"
                v-if="!loading"
              >
                <p>
                  <i
                    class="mdi mdi-magnify mdi-24px"
                    style="position: relative; top: 4px; margin-right: 16px;"
                  ></i>
                  Brak wyników wyszukiwania.
                </p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import debounce from "@/utils/debounce";
import YMmodal from "@/components/Modal.vue";
import TriStateCheckbox from "@/components/TriStateCheckbox.vue";

export default {
  name: "Service",
  components: {
    FixedHeader,
    TriStateCheckbox,
  },
  data() {
    return {
      orders: [],
      dates: [],
      status_id: 30,
      loading: false,
      total: 0,
      sortField: "number",
      sortOrder: "desc",
      defaultSortOrder: "asc",
      page: 1,
      perPage: 50,
      searchQuery: "",
      searchDeleted: 0,
      selected: null,
      filters: {
        is_fv: 0,
        is_paid: 0,
        is_warranty: 0,
        is_service: 0,
        is_trade: 0,
        is_np: 0,
        unfinished: 0,
      },
    };
  },
  watch: {
    searchQuery() {
      this.debouncedSearchQuery();
    },
    filters: {
      handler() {
        this.debouncedSearchQuery();
      },
      deep: true,
    },
  },
  mounted() {
    this.$refs.searchInput.$el.children[0].focus();
  },
  created() {
    this.debouncedSearchQuery = debounce(this.getOrders, 500);
    this.getOrders();
    document.addEventListener("keyup", this.tableNav);
    Array.from(document.querySelectorAll("table tbody tr")).forEach((node) => {
      node.classList.add("normal");
    });
  },
  destroyed() {
    document.removeEventListener("keyup", this.tableNav);
  },
  computed: {
    ...mapGetters({
      technical_id: "auth/userId",
      unfinishedOrders: "auth/unfinishedOrders",
    }),
    trows() {
      return document.querySelector("table").rows;
    },
    repared_at_from() {
      return this.dates[0];
    },
    repared_at_to() {
      return this.dates[1];
    },
  },
  methods: {
    ...mapActions({
      getOrdersCall: "orders/getOrders",
      removeOrderCall: "orders/removeOrder",
    }),
    unfinishedOrdersCall() {
      this.status_id = null;
      this.filters.unfinished = 1;
      // this.getOrders()
    },
    getOrders() {
      this.loading = true;
      const {
        page,
        perPage,
        sortField,
        sortOrder,
        searchQuery,
        searchDeleted,
        repared_at_from,
        repared_at_to,
        technical_id,
        status_id,
        filters,
      } = this;

      this.getOrdersCall({
        page,
        perPage,
        sortField,
        sortOrder,
        searchQuery,
        searchDeleted,
        repared_at_from,
        repared_at_to,
        technical_id,
        status_id,
        filters,
      })
        .then((resp) => {
          this.total = resp.page.items;
          if (this.total <= this.perPage) {
            document.querySelector(".top .pagination").style.display = "none";
          }
          this.orders = resp.data;
        })
        .catch((error) => {
          this.orders = [];
          this.total = 0;
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        })
        .finally(() => (this.loading = false));
    },
    orderRemove(id) {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć zlecenie?",
          content: `<span class='is-danger'>Usunięcia zlecenia nie można cofnąć.</span> Zlecenie pozostanie w utworzonych do tej pory protokołach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeOrderCall({ id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.getOrders();
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    onPageChange(page) {
      this.page = page;
      if (this.searchQuery === "" || this.searchQuery === null) {
        this.getOrders();
      }
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.getOrders();
    },
    tableNav($event) {
      var trows = this.trows,
        trow,
        nextrow;

      switch ($event.keyCode) {
        case 13: {
          return active();
        }
        case 38: {
          return movehighlight(-1, $event);
        }
        case 40: {
          return movehighlight(1, $event);
        }
        default: {
          return true;
        }
      }

      function active() {
        document.querySelector("table tbody tr.is-selected a").click();
      }

      function movehighlight(way, e) {
        e.preventDefault && e.preventDefault();
        e.returnValue = false;
        var idx = highlightRow(true); //gets current index or null if none highlighted

        if (typeof idx === "number") {
          //there was a highlighted row
          idx += way; //increment\decrement the index value
          if (idx && (nextrow = trows[idx])) {
            return highlightRow.apply(nextrow);
          } //index is > 0 and a row exists at that index
          else if (idx) {
            return highlightRow.apply(trows[1]);
          } //index is out of range high, go to first row
          return highlightRow.apply(trows[trows.length - 1]); //index is out of range low, go to last row
        }
        return highlightRow.apply(trows[way > 0 ? 1 : trows.length - 1]); //none was highlighted - go to 1st if down arrow, last if up arrow
      }

      function highlightRow(gethighlight) {
        //now dual use - either set or get the highlighted row
        gethighlight = gethighlight === true;
        var t = trows.length;
        while (--t > -1) {
          trow = trows[t];
          if (gethighlight && trow.className === "is-selected") {
            return t;
          } else if (!gethighlight && trow !== this) {
            trow.className = "normal";
          }
        } //end while

        return gethighlight
          ? null
          : (this.className =
              this.className === "is-selected" ? "normal" : "is-selected");
      }
    },
    toggleSearchDeleted() {
      this.debouncedSearchQuery();
    },
    addNewOrder() {
      Promise.all([
        this.$store.commit("orders/setOrder", {}),
        this.$store.commit("orders/setCustomer", {}),
        this.$store.commit("orders/setTechnician", {}),
        this.$store.commit("orders/setOperator", {}),
        this.$store.commit("orders/setDevice", {}),
      ]).then(() => this.$router.push({ name: "ServiceAdd" }));
    },
    searchOrdersFromRange() {
      this.getOrders();
    },
    searchOrdersFromStatus() {
      this.getOrders();
    },
    allOrders() {
      this.dates = [];
      this.getOrders();
    },
    statusName: function(id) {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === id
      );
      if (status.length > 0) {
        return status[0].name;
      }
    },
    statusColor: function(id) {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === id
      );
      if (status.length > 0) {
        return `color: ${status[0].colour}`;
      }
    },
    isOldOrder(order) {
      function isDateGreater(d1, d2, days) {
        d1 = new Date(d1);
        return +new Date(d2) > d1.setDate(d1.getDate() + (days || 0));
      }
      if (
        order.status_id === 40 &&
        isDateGreater(order.repared_at, new Date(), 4)
      ) {
        return "is-old";
      }
      if (
        order.status_id !== 40 &&
        isDateGreater(order.repared_at, new Date(), 1)
      ) {
        return "is-unfinished";
      }
      return;
    },
  },
};
</script>

<style scoped>
.alert {
  padding: 16px;
  background-color: #fbe8ea !important;
  border: 1px solid #da2032 !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.2);
  color: #ff0000;
}
.alert b {
  font-size: 18px;
  margin-bottom: 8px;
}
.alert .button {
  margin-top: 16px;
  display: inline-flex;
  color: #ff0000;
}
</style>
